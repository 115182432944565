import css from './LogReg.module.css'

export default function LogReg () {

    return (
        <div className={css.logReg}>
                                <ul>
                                    <li>
                                        <a href="/login">Login</a>
                                    </li>
                                    <li>
                                        <small>|</small>
                                    </li>
                                    <li>
                                        <a href="/register">Register</a>
                                    </li>
                                </ul>
                            </div>
    )
}