import CourseCard from "./courses/CourseCard";

import css from "./Upcoming.module.css";

export default function Upcoming() {
	let upcomingCourses = [
		// {
		// image: "/img/A320neo.jpg",
		// 	title: "Differences from A320(CFM56, V2500 to A320(CFM LEAP)",
		// 	date: "26 July 2023",
		// 	time: "17:00 EET",
		// 	duration: "3 days",
		// 	location: "Online (Zoom)",
		// 	price: "on request",
		// 	practical: "Sofia Airport, Bulgaria",
		// },
		// {
		// image: "/img/A320neo.jpg",
		// 	title: "Differences from A320(CFM56, V2500 to A320(PW1100)",
		// 	date: "31 July 2023",
		// 	time: "17:00 EET",
		// 	duration: "3 days",
		// 	location: "Online (Zoom)",
		// 	price: "on request",
		// 	practical: "Sofia Airport, Bulgaria",
		// },
		// {
		// 	key: 1,
		// 	image: "/img/A320neo.jpg",
		// 	title: "A320neo (CFM LEAP, PW1100G) differences from A320ceo series",
		// 	date: "08 April 2024",
		// 	time: "17:00 EET",
		// 	duration: "6 days TT + 2 days PT",
		// 	location: "Online (Zoom)",
		// 	price: "on request",
		// 	practical: "Sofia Airport, Bulgaria",
		// 	form: "https://forms.gle/B2HrY1NPFebU5pTW9",
		// },
		{
			key: 1,
			image: "/img/B737max.jpg",
			title: "Diff from B737NG (CFM56) to B737MAX (CFM LEAP) B1/B2",
			date: "08 July 2024",
			time: "17:00 EET",
			duration: "9 days TT + 3 days PT",
			location: "Online (Zoom)",
			price: "on request",
			practical: "TBD",
			form: "https://forms.gle/nkYBPBJu96cVjNKR6",
		},
		// {
		// 	key: 2,
		// 	image: "/img/B737ng.jpg",
		// 	title: "Differences from Boeing 737 CL to Boeing 737 NG",
		// 	date: "04 March 2024",
		// 	time: "17:00 EET",
		// 	duration: "12 days TT (incl. 5 days self-study) + 3 days PT",
		// 	location: "Online (Zoom)",
		// 	price: "on request",
		// 	practical: "Sofia Airport, Bulgaria",
		// 	form: "https://forms.gle/beLJv9e3oWBE8izy5",
		// },
		// {
		// 	image: "/img/A320ceo.jpg",
		// 	title: "Airbus A320 series (CFM56, V2500) Cat. B1/B2",
		// 	date: "May 2024",
		// 	time: "17:00 EET",
		// 	duration: "26 days + 5 days SS",
		// 	location: "Online (Zoom)",
		// 	price: "on request",
		// 	practical: "Sofia Airport, Bulgaria",
		// 	form: "https://forms.gle/P2tKTsgytSX9voH89",
		// },
	];

	return (
		<section>
			<div className='container'>
					{/* <!--start heading--> */}
					<div className={css['course-heading']}>
						<div className={css['sec-heading']}>
							<h2>Upcoming Courses</h2>
						</div>
					</div>
					{/* <!--end heading--> */}

				<div className={css["flex"]}>
					{upcomingCourses.map((x) => {
						return <CourseCard data={x}/>;
					})}
				</div>

				{/* <div className='row'>
					<div className='col-lg-12'>
						<div className='category-btn btn-default text-center'>
							<a href='#'>all courses</a>
						</div>
					</div>
				</div> */}
			</div>
		</section>
	);
}
